import React from "react";
import TileContainer from "~2-components/TileContainer/TileContainer";
import { ResultsProps } from "~2-components/FilteredListing/FilteredListingType";
import NoItems from "./NoItems";
import ItemPagination from "./ResultsPagination";
import TileFilter from "../Tiles/TileFilter";

const Results = (props: ResultsProps) => {
  const {
    items,
    tileContainerColumns,
    tileType,
    totalPages,
    currentPage,
    changePage,
    resetFilters,
    showPagination,
  } = props;

  return (
    <>
      <div className="fl-results">
        {!items.length && <NoItems resetFilters={resetFilters} />}
        {items.length > 0 && (
          <TileContainer columns={tileType === 'profiles' ? 'four_columns': tileContainerColumns}>
            {items.map((item) => (
              <TileFilter
                key={`${item.guid}`}
                item={item}
                tileType={tileType}
              />
            ))}
          </TileContainer>
        )}
      </div>
      {items.length > 0 && !!showPagination && (
        <ItemPagination
          totalPages={totalPages}
          currentPage={currentPage}
          changePage={changePage}
        />
      )}
    </>
  );
};

export default Results;
