import React from 'react';
import {
    FiltersProps,
    FilterOptions,
} from '~2-components/FilteredListing/FilteredListingType';
// import Icon from '~2-components/IconInline/IconInline';
import * as FilterTypeConstants from '../Constants/filter-types';
import { getActiveValues } from '../utils/active-filters';
import KeywordFilter from './KeywordFilter/KeywordFilter';
import TagFilter from './TagFilter/TagFilter';
import MultiCheckboxFilter from './MultiCheckboxFilter/MultiCheckboxFilter';

const Filters = (props: FiltersProps) => {
    const {
        filters,
        filterSettings,
        activeFilters,
        toggleFilters,
        resetFilters,
        uniqueId,
    } = props;

    const keyWordFilters = filters.filter(filter => filter.type === FilterTypeConstants.FILTER_TYPE_KEYWORDS);
    const multiCheckFilters = filters.filter(filter => filter.type === FilterTypeConstants.FILTER_TYPE_MULTI_CHECKBOX);
    const tagFilters = filters.filter(filter => filter.type === FilterTypeConstants.FILTER_TYPE_TAGS);

    return (
        <div className="fl-filters">
            {multiCheckFilters.length > 0 && (
            <div className="filter-group">
                <div className="title-label">{(filterSettings && filterSettings.filterTitleLabel)? filterSettings.filterTitleLabel : ''}</div>
                <div className="multicheck-filters">
                        {multiCheckFilters.map((filter: FilterOptions) => {
                                //sort filter values alphabetically
                                filter.values = filter.values.sort((a, b) => {
                                    // @ts-ignore
                                    if (a.param < b.param) {
                                        return -1;
                                    }
                                    // @ts-ignore
                                    if (a.param > b.param) {
                                        return 1;
                                    }
                                    return 0;
                                });
                            const activeValues = getActiveValues(filter, activeFilters);
                            return (
                                <MultiCheckboxFilter
                                        key={filter.code}
                                        filter={filter}
                                        selectedItems={activeValues}
                                        toggleFilters={toggleFilters}
                                        classModifier="filter-item"
                                        uniqueId={uniqueId}
                                        placeholder={activeValues.length
                                            ? activeValues[0].value
                                            : filter.label
                                        }
                                    />
                            );
                        })}
                </div>
            </div>
            )}
            {keyWordFilters.length > 0 && (
            <div className="filter-group">
                <div className="title-label">{(filterSettings && filterSettings.searchTitleLabel)? filterSettings.searchTitleLabel : ''}</div>
                <div className="keyword-filters">
                    {keyWordFilters.map((filter: FilterOptions) => {
                        const activeValues = getActiveValues(filter, activeFilters);
                        return (
                            <KeywordFilter
                                key={filter.code}
                                filter={filter}
                                keyword={
                                    activeValues.length
                                        ? activeValues[0].value
                                        : ''
                                }
                                toggleFilters={toggleFilters}
                                placeholderText={
                                    filterSettings.keywordPlaceholderText
                                        ? filterSettings.keywordPlaceholderText
                                        : 'Enter keywords'
                                }
                                classModifier="filter-item"
                                uniqueId={uniqueId}
                            />
                        );
                    })}
                </div>
            </div>
            )}
            {tagFilters.length > 0 && (
            <div className="filter-group">
                <div className="tag-filters">
                    {tagFilters.map((filter: FilterOptions) => {
                        const activeValues = getActiveValues(filter, activeFilters);
                        const { multiSelect = true } = filter;
                        return (
                                <TagFilter
                                    key={filter.code}
                                    filter={filter}
                                    selectedItems={activeValues}
                                    toggleFilters={toggleFilters}
                                    resetFilters={resetFilters}
                                    multiSelect={multiSelect}
                                    classModifier="filter-item"
                                />
                        );
                    })}
                </div>
            </div>
            )}
        </div>
    );
};

export default Filters;
