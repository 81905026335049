import {
    FilterOptions,
    FilterOptionValue,
} from '~2-components/FilteredListing/FilteredListingType';

// Fill all values with []
export const initActiveFilters = (filters: FilterOptions[]) => {
    if (!filters || !filters.length) {
        return [];
    }
    const filterValue = filters.map(item => {
        const newValues: FilterOptionValue[] = [];
        return {
            ...item,
            values: newValues,
        };
    });
    return filterValue as FilterOptions[];
};

/**
 * Return a new array of active filters with empty values based on filterOptions object from api data
 *
 * @param {Array} filters - The active filters to check
 * @returns {Array} Returns - the active filters that has values
 */
export const getActiveFiltersWithValues = (filters: FilterOptions[]) => {
    // Early return if filters is not supplied
    if (!filters || !filters.length) {
        return [];
    }

    // Only return the filter items with values
    // return filters.filter(item => item.values && item.values.length);
    return filters.filter(item => {
        let returnItem;

        if (item.values && item.values.length) {
            returnItem = item;
        }

        return returnItem;
    }) as FilterOptions[];
};

// Find the active filter values of the specific filter
export const getActiveValues = (
    filter: FilterOptions,
    activeFilters: FilterOptions[],
) => {
    const found = activeFilters.find(item => item.code === filter.code);
    const value = found ? found.values : [];
    return (value as unknown) as FilterOptionValue[];
};

// Update Active Filter on certain index with new value (add if not available / remove if available)
export const updateActiveFiltersOnIndex = (
    activeFilters: FilterOptions[],
    activeFilterIndex: number, // which active filters index will be updated
    filterValue: FilterOptionValue,
    alwaysReplace = false,
    replaceGlobal = false,
): FilterOptions[] => {
    const activeFilter = activeFilters[activeFilterIndex];

    // Clone of active filters
    const newActiveFilters = alwaysReplace && replaceGlobal
        ? initActiveFilters(activeFilters)
        : JSON.parse(JSON.stringify(activeFilters));

    let newValues: FilterOptionValue[] = [];

    if (alwaysReplace) {
        // Force to always replace the value into array[0] (for keywords filter)
        if (filterValue.value) {
            newValues = [filterValue];
        }
    } else if (
        !activeFilter.values.find(item => item.param === filterValue.param)
    ) {
        // If not found inside the [].values, add it
        newValues = [...activeFilter.values, filterValue];
    } else {
        // If found, delete it
        newValues = activeFilter.values.filter(
            item => item.param !== filterValue.param,
        );
    }

    // Replace with new values
    newActiveFilters[activeFilterIndex] = {
        ...activeFilter,
        values: newValues,
    };

    return newActiveFilters;
};

export const isAllActiveFiltersEmpty = (activeFilters: FilterOptions[]) => {
    const filterWithValues = activeFilters.filter(
        item => item.values.length > 0,
    );
    return filterWithValues.length < 1;
};

export const isRequiredActiveFiltersEmpty = (
    activeFilters: FilterOptions[],
) => {
    const filterWithValues = activeFilters.filter(
        item => item.required && item.values.length < 1,
    );
    return filterWithValues.length >= 1;
};

export default {
    initActiveFilters,
    getActiveFiltersWithValues,
    getActiveValues,
    isAllActiveFiltersEmpty,
};
