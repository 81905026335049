import {
    Item,
    FilterOptions,
    FilterOptionValue,
} from '~2-components/FilteredListing/FilteredListingType';
import findByMatchingProperties from '~2-components/FilteredListing/utils/find-matching-properties';

export const passesTagsFilter = (item: Item, filter: FilterOptions) => {
    // IF the active filter does not have values then we pass the check without question
    if (!filter.values.length) {
        return true;
    }

    // Find the filter option on the item that matches the active filter
    const matchedItemFilterOptions = findByMatchingProperties(
        item.filterOptions,
        { code: filter.code },
    );

    // if we did not find a match, then this item does not pass the filtering
    if (!matchedItemFilterOptions || !matchedItemFilterOptions.length) {
        return false;
    }

    let isPasses = false;

    // find values.params in filter.params, if found, then it is passed
    matchedItemFilterOptions.forEach(matchedItemFilterOption =>
        matchedItemFilterOption.values.forEach(
            (matchedValue: FilterOptionValue) => {
                const filteredValue = filter.values.find(
                    filterValue => filterValue.param === matchedValue.param,
                );

                // IF one of them is true it is true
                if (filteredValue) {
                    isPasses = true;
                }
            },
        ),
    );

    return isPasses;
};

export default passesTagsFilter;
