import React from 'react';

export interface LoaderProps {
    message?: string;
}

const Loader = ({ message }: LoaderProps) => (
    <div className="loader-wrapper">
        <div className="loader" />
        {message && <h4>{message}</h4>}
    </div>
);

export default Loader;
