// import clonedeep from 'lodash.clonedeep';
// import { getActiveFiltersWithValues } from './active-filters';
import * as FilterTypeConstants from '../Constants/filter-types';
import passesTagsFilterFunc from './filters/tags';
import passesKeywordsFilterFunc from './filters/keywords';
import { Item, FilterOptions, SortBy } from '../FilteredListingType';

/**
 * Return a filtered set of items based on a filter object and object keys
 *
 * @param {Array} filters - The array of filter objects to filter the items array by
 * @param {Array} items - The items to filter
 * @returns {Array} Returns - A filtered set of data
 */
export const filterItems = (
    filters: FilterOptions[],
    items: Item[],
    keywordSearchableFields?: string[],
): Item[] => {
    // Make a clone of the items so filtering doesn't affect the main set
    let filteredItems = JSON.parse(JSON.stringify(items)) as Item[];

    // Only keep th items that matches the filter and keyword search
    filteredItems = filteredItems.filter(item => {
        // If no filters supplied, then the item passes without question
        if (!filters || !filters.length) {
            return true;
        }

        // find filters that have values. I.e. activeFilters.
        // const activeFilters = getActiveFiltersWithValues(filters);

        // loop through every activeFilter
        let filterPass = true;
        filters.forEach(filter => {
            // Handles different types of filters differently
            switch (filter.type) {
                case FilterTypeConstants.FILTER_TYPE_KEYWORDS:
                    if (!keywordSearchableFields) return;
                    if (
                        !passesKeywordsFilterFunc(
                            item,
                            filter,
                            keywordSearchableFields,
                        )
                    ) {
                        filterPass = false;
                    }
                    break;
                case FilterTypeConstants.FILTER_TYPE_MULTI_CHECKBOX:
                    if (!passesTagsFilterFunc(item, filter)) {
                        filterPass = false;
                    }
                    break;
                case FilterTypeConstants.FILTER_TYPE_TAGS:
                    if (!passesTagsFilterFunc(item, filter)) {
                        filterPass = false;
                    }
                    break;

                default:
                    // No filter type found, make the filter fail
                    filterPass = false;
            }
        });

        // Return filter pass result
        return filterPass;
    });

    return filteredItems;
};

/**
 * Sort the items passed in based on the sort object
 *
 * @param {Array} items - The items to sort - it has to be an array of objects
 * @param {object} currentSortObject - The sort to apply, it has to be an object with 'field' and 'ascending' attribute
 * @returns {Array} Returns - A sorted set of data
 */
export const sortItems = (items: Item[], currentSortObject: SortBy): Item[] => {
    const newItems = JSON.parse(JSON.stringify(items)) as Item[];
    // If sort is not specified, return items as they are
    if (!currentSortObject) {
        return newItems;
    }
    let initialSort = newItems;

    // Sort first alphabetically by title
    if (currentSortObject.field === 'sort_position') {
        initialSort = newItems.sort(function(a, b) {
            var textA = a.title.toUpperCase();
            var textB = b.title.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
    }

    // Sort the items based on the current sort target field and direction
    return initialSort.sort((a, b) => {
        // @ts-ignore
        if (a[currentSortObject.field] < b[currentSortObject.field]) {
            return currentSortObject.ascending ? -1 : 1;
        }
        // @ts-ignore
        if (a[currentSortObject.field] > b[currentSortObject.field]) {
            return currentSortObject.ascending ? 1 : -1;
        }

        return 0;
    });
};

// currentPage start with 0
export const paginateItems = (
    items: Item[],
    currentPage: number,
    perPage: number,
): Item[] => {
    const startAt = perPage * currentPage;
    const paged = items.filter(
        (item, index) => index >= startAt && index < startAt + perPage,
    );
    return paged;
};

export default {
    filterItems,
    sortItems,
    paginateItems,
};
