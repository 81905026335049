import React from 'react';
import {
    TagFilterProps,
    FilterOptionValue,
} from '~2-components/FilteredListing/FilteredListingType';

const TagFilter = (props: TagFilterProps) => {
    const {
        filter,
        selectedItems,
        resetFilters,
        toggleFilters,
        multiSelect,
    } = props;
    const { label } = filter;

    return (
        <div className="tag-filter filter-item">
            {!!label && <h5 className="tag-filter-title">{label}</h5>}
            <div className="tag-filter-items">
                <div
                    className={`tag-filter-item ${
                        selectedItems.length === 0 ? 'active' : ''
                    }`}
                >
                    <button
                        type="button"
                        className="tag-filter-item-button"
                        onClick={resetFilters}
                    >
                        <small>All {filter.label}</small>
                    </button>
                </div>
                {filter.values.map((item: FilterOptionValue) => (
                    <div
                        key={item.param}
                        className={`tag-filter-item
                        ${
                    selectedItems.find(
                        selected => selected.param === item.param,
                    )
                        ? 'active'
                        : ''
                    }`}
                    >
                        <button
                            type="button"
                            className="tag-filter-item-button"
                            onClick={() => {
                                if (multiSelect) {
                                    toggleFilters(filter, item);
                                } else if (!selectedItems.includes(item)) {
                                    // If multi select available, and the value is not already selected
                                    toggleFilters(filter, item, true);
                                }
                            }}
                        >

                            {/* <p dangerouslySetInnerHTML={{ __html: item.value }} /> */}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TagFilter;
