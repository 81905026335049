import React from 'react';
import Icon from '~2-components/IconInline/IconInline';
import { FILTER_TYPE_SINGLE_CHECKBOX } from '../Constants/filter-types';
import { ActiveFiltersProps } from '../FilteredListingType';
import { isAllActiveFiltersEmpty } from '../utils/active-filters';

const ActiveFilters = (props: ActiveFiltersProps) => {
    const {
        activeFiltersLabel,
        activeFilters,
        showActiveFilters,
        resetFilters,
        toggleFilters,
    } = props;

    if (
        !activeFilters.length
        || isAllActiveFiltersEmpty(activeFilters)
        || !showActiveFilters
    ) {
        return null;
    }

    return (
        <div className="fl-active-filters">
            {activeFiltersLabel && (
                <div className="active-filters-label active-filters-element">
                    {activeFiltersLabel}
                </div>
            )}

            {activeFilters.map(filter => filter.values.map(filterValue => (
                <button
                    key={`${filter.code}-${filterValue.value}`}
                    className="active-filters-item active-filters-element"
                    onClick={() => toggleFilters(filter, filterValue)}
                    tabIndex={0}
                    type="button"
                >
                    <span className="active-filters-item-label">
                        {filter.type === FILTER_TYPE_SINGLE_CHECKBOX
                            ? filter.label
                            : filterValue.value}
                    </span>
                    <Icon icon={'close'} />
                </button>
            )))}

            <button
                className="active-filters-reset-button active-filters-element"
                onClick={resetFilters}
                tabIndex={0}
                type="button"
            >
                <span>Reset Filters</span>
            </button>
        </div>
    );
};

export default ActiveFilters;
