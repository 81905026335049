import React from 'react';
import { PageViewProps } from './PaginationType';
import { PAGINATION_SELECTED_CLASSNAME } from './Constants';

const PageView = (props: PageViewProps) => {
    const {
        className = '',
        selected = false,
        page = 0,
        queryParamPage = 'page',
        onClick,
    } = props;

    const pageClass = selected
        ? `${className} ${PAGINATION_SELECTED_CLASSNAME}`
        : className;

    const url = (typeof window !== 'undefined')
        ? `${window.location.href.split('?')[0]}?${queryParamPage}=${page}`
        : '';

    return (
        <li className={`pagination-item pagination-item-page ${pageClass}`}>
            <a href={url} onClick={onClick} className="pagination-item-content">
                {page}
            </a>
        </li>
    );
};

export default PageView;
