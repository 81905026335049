export const toSnakeCase = (str: string) => {
    const newStr = str.match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
    );
    if (newStr) {
        return newStr.map(x => x.toLowerCase()).join('_') as string;
    }
    return '';
};

export default {
    toSnakeCase,
};
