import { FilterOptions } from '~2-components/FilteredListing/FilteredListingType';

/**
 * Return a new array of key strings
 *
 * @param {Object} filters - filter items
 * @returns {Array} Returns - Array containing list of keys
 *
 * Example usage:
 * const filterKeys = getFilterKeys(this.props.filters);
 * const activeFiltersKeys = getFilterKeys(this.props.activeFilters);
 *
 */
export const getFilterKeys = (filters: FilterOptions[] = []): string[] => {
    const filterKeys: string[] = [];

    filters.map(key => filterKeys.push(key.queryParam));

    return filterKeys;
};

export default {
    getFilterKeys,
};
