import React, { ChangeEvent } from 'react';
import Icon from '~2-components/IconInline/IconInline';

interface CheckboxProps {
    className?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    required?: boolean;
    disabled?: boolean;
    label: string;
    id: string;
    name?: string;
    value?: string;
    checked?: boolean;
}

const Checkbox = (props: CheckboxProps) => {
    const {
        label = 'checkbox item',
        name,
        value,
        id,
        className = '',
        onChange,
        onKeyPress,
        required = false,
        disabled = false,
        checked,
    } = props;

    const classOutput = `checkbox-wrapper ${className}`;

    return (
        <div className={classOutput}>
            <label htmlFor={id}>
                <input type="checkbox" id={id} name={name} value={value} required={required} disabled={disabled} onChange={onChange} onKeyPress={onKeyPress} checked={checked} />
                <span className="input-label">{label}</span>
                <Icon icon="check-box-outline" className="unchecked svg-icon" />
                <Icon icon="check-box" className="checked svg-icon" />
            </label>
        </div>
    );
};

export default Checkbox;
