import {
    FilterOptions,
    Item,
} from '~2-components/FilteredListing/FilteredListingType';

export const passesKeywordsFilter = (
    item: Item,
    filter: FilterOptions,
    searchFields: string[],
) => {
    // If no keyword supplied, then the item passes without question
    if (!filter.values.length || !filter.values[0] || !filter.values[0].value) {
        return true;
    }

    // For each field specified in the strings array, build a string to search through - if not specified we search the title and excerpt
    const stringFields = searchFields && searchFields.length
        ? searchFields
        : ['title', 'introduction'];
    let searchString = '';
    stringFields.forEach(field => {
        // Handle arrays differently
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (Array.isArray(item[field])) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            searchString += item[field].join(' ').toLowerCase();
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            searchString += `${item[field]} `.toLowerCase();
        }
    });

    // Return the item if the string matches our input
    return searchString.indexOf(filter.values[0].value.toLowerCase()) !== -1;
};

export default passesKeywordsFilter;
