/**
 * determine if an array contains one or more items from another array.
 *
 * @param {array} whitelist the array to search.
 * @param {array} urlParams the array providing items to check for in the whitelist.
 * @return {boolean} true|false if whitelist contains at least one item from urlParams.
 *
 * Example usage:
 * const hasValidParams = validateAgainstWhitelist(whitelist, params);
 *
 */
// eslint-disable-next-line
export const validateAgainstWhitelist = (whitelist: string[], urlParams: string[]): boolean =>
    urlParams.some((v) => whitelist.includes(v));

export default {
    validateAgainstWhitelist,
};
