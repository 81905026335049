import React from "react";
import { DebounceInput } from "react-debounce-input";
import Icon from "~2-components/IconInline/IconInline";
import {
  KeywordFilterProps,
  FilterOptionValue,
} from "~2-components/FilteredListing/FilteredListingType";
import { toSnakeCase } from "../../utils/string";

const KeywordFilter = (props: KeywordFilterProps) => {
  const {
    filter,
    toggleFilters,
    classModifier,
    placeholderText,
    keyword,
    uniqueId
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // let filterValue: FilterOptionValue | null;

    const filterValue: FilterOptionValue | null =
      e.target.value !== null
        ? {
            value: e.target.value,
            param: toSnakeCase(e.target.value),
          }
        : null;

    if (!filterValue) return null;

    return toggleFilters(filter, filterValue, true);
  };

  const inputId = `keyword-search-${filter.code}-${uniqueId}`;

  return (
    <div className={`keyword-filter ${classModifier}`}>
      <div className="label-wrapper hidden">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={inputId}>Keyword Search</label>
      </div>
      <div className="input-wrapper">
        <DebounceInput
          debounceTimeout={300}
          type="text"
          maxLength="250"
          id={inputId}
          value={keyword}
          onChange={handleChange}
          // onKeyPress={handleKeyPress}
          placeholder={placeholderText}
          data-testid={filter.code}
        />
        <Icon icon={"search"} />
      </div>
    </div>
  );
};

export default KeywordFilter;
