import React from 'react';
import { BreakViewProps } from './PaginationType';

const BreakView = (props: BreakViewProps) => {
    const { breakLabel = '...', breakClassName = 'break disabled' } = props;
    return (
        <li className={breakClassName}>
            <span>{breakLabel}</span>
        </li>
    );
};

export default BreakView;
