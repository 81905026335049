import React from "react";
import NewsTile from "~2-components/NewsTile/NewsTile";
import ProfileTile from "~2-components/ProfileTile/ProfileTile";
import { PEOPLE_OPTION_CODENAME } from "~/3-data-components/FilteredListing/Constants";
import GatsbyImage from 'gatsby-image';

// eslint-disable-next-line
import {
  TileFilterProps,
} from "~2-components/FilteredListing/FilteredListingType";

const TileFilter = (props: TileFilterProps) => {
  const { item, tileType } = props;
  // Required fields on all item types
  const { title, url, date, image } = item;
  const cmsImage = (image && image.length > 0 && image[0].fluid) ? image[0] : null;
  const imgUrl = ((image && image.length > 0 ) && image[0].url) ? image[0].url : '';
  switch (tileType) {
    case PEOPLE_OPTION_CODENAME: {
      const {
        guid,
        accredited,
        firstName,
        lastName,
        position,
        phone,
        email,
        linkedIn,
        urlSlug
      } = item;
      return (
        <ProfileTile
          key={`${guid}-profile-tile`}
          accredited={accredited}
          firstName={firstName}
          lastName={lastName}
          position={position}
          phone={phone}
          email={email}
          linkedIn={linkedIn}
          urlSlug={urlSlug}
          cmsImage={cmsImage ?
            <GatsbyImage
                key={cmsImage.name}
                alt={cmsImage.description}
                fluid={cmsImage.fluid}
            />
          : null}
          {...item}
        />
      );
    }

    default: {
      const { guid } = item;
      return (
        <NewsTile
          key={`${guid}-news-tile`}
          title={title}
          url={url}
          date={date as string}
          cmsImage={cmsImage ?
            <GatsbyImage
                key={cmsImage.name}
                alt={cmsImage.description ? cmsImage.description : ''}
                fluid={cmsImage.fluid}
            />
          : null}
          imgUrl={imgUrl}
          imgAlt={title}
        />
      );
    }
  }
};

export default TileFilter;
