import { getActiveFiltersWithValues } from './active-filters';
import querystringWhitelist from '../Constants/querystring-whitelist';
import { QUERY_PARAM_SORT, QUERY_PARAM_PAGE } from '../Constants/filter-keys';
import { getFilterKeys } from './filters/filter-helpers';
import { validateAgainstWhitelist } from './querystring-whitelist-helpers';
import { FilterOptions, FilterOptionValue } from '../FilteredListingType';

const START_OBJECT = '={';
const END_OBJECT = '}';

/**
 * Return a new String of of url query params
 *
 * @returns {String} Returns - String of query params for current page url
 *
 * Example usage:
 * const queryString = getQueryString;
 *
 */
export const getQueryString = (): string => window.location.search.substring(1);

/**
 * Return a new array of strings
 *
 * @param {Array} queryStringParams - Array of queryString key/value pairs
 * @returns {Array} Returns - New array of strings
 *
 * Example usage:
 * const vars = url.split('&');
 * const qsKys = getQueryStringKeys(vars);
 *
 */

export const getQueryStringKeys = (queryStringParams: string[]) => {
    const keys: string[] = [];

    queryStringParams.forEach(key => {
        const k = key.replace(/=(.+)/, '');
        keys.push(k);
    });

    return keys;
};

const getQueryParamValue = (queryString: string, queryParam: string): string | null => {
    const decodedQS = decodeURIComponent(queryString).trim();
    const searchKey = queryParam + START_OBJECT;
    const startIndex = decodedQS.indexOf(searchKey);
    const endIndex = decodedQS.indexOf(END_OBJECT, startIndex);

    // if queryParam not found return null
    if (startIndex < 0 || endIndex < 0) {
        return null;
    }

    const listingQS = decodedQS.substring(startIndex + searchKey.length, endIndex);

    return listingQS;
};

// keep existing query string beside the query param, and insert the new filter string
const persistQueryString = (queryString: string, queryParam: string, filterString: string[]) => {
    let decodedQS = decodeURIComponent(queryString);
    const searchKey = queryParam + START_OBJECT;
    const startIndex = decodedQS.indexOf(searchKey);
    const endIndex = decodedQS.indexOf(END_OBJECT, startIndex);

    // if filterString is empty remove any parameter that's related to queryParam;
    if (!filterString.length) {
        // if queryParam not found return queryString as is
        if (startIndex < 0) {
            return queryString;
        }

        // if character before is '&' remove it as well
        const updatedStartIndex = (decodedQS.charAt(startIndex - 1) === '&') ? startIndex - 1 : startIndex;
        return decodedQS.substring(0, updatedStartIndex) + decodedQS.substring(endIndex + END_OBJECT.length);
    }

    // found key query param but not found the '}' ending (not valid)
    if (startIndex >= 0 && endIndex < 0) {
        return decodedQS;
    }

    const filterStringParam = filterString.join('&');
    const paramResult = `${searchKey}${filterStringParam}${END_OBJECT}`;

    // if key parameter not found, add it
    if (startIndex < 0) {
        // if initial qs is empty no need to add '&'
        decodedQS = (decodedQS.length) ? `${decodedQS}&${paramResult}` : paramResult;
    } else {
        decodedQS = decodedQS.substring(0, startIndex) + paramResult + decodedQS.substring(endIndex + END_OBJECT.length);
    }

    return decodedQS;
};

export const parseQueryString = (
    queryString: string,
    filters: FilterOptions[],
    pageNum = 0,
    filterOptions: FilterOptions[],
    queryParam: string,
) => {
    // Default (empty) object of possible filters
    const obj = {
        activeFilters: [...filters],
        [QUERY_PARAM_PAGE]: pageNum,
        [QUERY_PARAM_SORT]: '',
    };

    // Return default object if query string is not set
    if (!queryString) {
        return obj;
    }

    const listingQS = getQueryParamValue(queryString, queryParam);

    if (!listingQS) return obj;

    const variables = listingQS.split('&');

    const varKeys = getQueryStringKeys(variables);
    const filterKeys = getFilterKeys(filters);
    const whitelist = [...querystringWhitelist, ...filterKeys, queryParam];
    const hasValidParams = validateAgainstWhitelist(whitelist, varKeys);

    // If the url does NOT have params in our whitelist, return null
    // prevents updating the url
    if (!hasValidParams) return null;

    variables.forEach(variable => {
        const arr = variable.split('=');
        const key = arr[0];
        const options = arr[1].split(',');

        const isValidKey = whitelist.indexOf(key) !== -1;

        // Only parse the string if key is in our whitelist AND we have options
        if (!isValidKey && (!options.length || !options[0])) {
            return;
        }

        if (key === QUERY_PARAM_SORT) {
            // Simple single value options
            obj[key] = decodeURIComponent(options[0]);
        } else if (key === QUERY_PARAM_PAGE) {
            if (/\d$/gi.test(options[0])) {
                // If we have only digits
                obj[key] = ((options[0] as unknown) as number) - 1; // eslint-disable-line prefer-destructuring
            }
        } else {
            // Parse active filters
            const currentFiltersParam = decodeURIComponent(options[0]).split(
                '+',
            );
            const currentFilters = currentFiltersParam.map(param => {
                // Find from original the key parameter (GS.CATEGORIES)
                const currentFilterOptions = filterOptions.find(
                    FO => FO.queryParam === key,
                );

                // If not found then just use the param to reduce error
                if (!currentFilterOptions) {
                    return ({
                        param,
                        value: param.replace(/_/g, ' '),
                    } as unknown) as FilterOptionValue;
                }

                // Find the correct value for that param
                const currentValue = currentFilterOptions.values.find(
                    cFO => cFO.param === param,
                );

                // if keyword there's no way for us to now which upper case which lower case
                // just use param but convert _ to spaces
                const value = !currentValue
                    ? param.replace(/_/g, ' ')
                    : currentValue.value;

                // return as an object
                return ({
                    param,
                    value,
                } as unknown) as FilterOptionValue;
            });
            // const currentFilters = decodeURIComponent(options[0]).replace(/_/g, ' ').split('+');

            obj.activeFilters.forEach(filter => {
                if (filter.queryParam === key) {
                    currentFilters.forEach(current => {
                        filter.values.push(current);
                    });
                }
            });
        }
    });

    return obj;
};

interface QueryData {
    activeFilters: FilterOptions[];
    currentSort: string | number;
    currentPage: number;
    queryParam: string;
}

// Build the query string from the object provided
export const buildQueryString = (
    queryData: QueryData,
    queryString = window.location.search.substring(1),
) => {
    // If we're passed null we assume filters are being cleared
    if (!queryData) {
        return '';
    }

    // Map the value from a complicated object
    const filterString: string[] = [];

    const {
        activeFilters, currentSort, currentPage, queryParam,
    } = queryData;

    if (activeFilters) {
        const selectedFilters = getActiveFiltersWithValues(activeFilters);

        selectedFilters.forEach(filter => {
            const opts = filter.values.map(opt => encodeURIComponent(opt.param).replace(/%20/g, '_'));
            filterString.push(`${filter.queryParam}=${opts.join('+')}`);
        });
    }

    if (currentSort && currentSort !== 'default') {
        filterString.push(
            `${QUERY_PARAM_SORT}=${encodeURIComponent(currentSort).replace(
                /%20/g,
                '_',
            )}`,
        );
    }
    if (currentPage && currentPage >= 0) {
        filterString.push(`${QUERY_PARAM_PAGE}=${currentPage + 1}`);
    }
    const endResult = persistQueryString(queryString, queryParam, filterString);

    return endResult;
};

export default {
    getQueryString,
    getQueryStringKeys,
    persistQueryString,
    parseQueryString,
    buildQueryString,
};
