import React, { useState, useRef, useEffect } from 'react';
import Icon from '~2-components/IconInline/IconInline';

interface SelectCustomProps {
    icon?: string;
    className?: string;
    children?: React.ReactNode;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    text?: string | null;
    id?: string;
}

const SelectCustom = (props: SelectCustomProps) => {
    const {
        icon = 'chevron-down',
        className = '',
        children,
        disabled = false,
        placeholder = '',
        text = null,
        id,
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const element = useRef<HTMLDivElement>(null);

    const onClick = () => {
        setIsOpen(!isOpen);
    };

    const handleOutsideClick = (e: Event) => {
        if (element && element.current && element.current.contains(e.target as Node | null)) {
            // handle click inside
            return;
        }
        // handle click outside
        setIsOpen(false);
    };

    // add & remove listener on didMount and unMount
    useEffect(() => {
        window.addEventListener('mousedown', handleOutsideClick);

        // returned function will be called on component unmount
        return () => {
            window.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const classOutput = `select-custom-wrapper ${className} ${isOpen ? 'is-open' : ''}`;
    const selectClassOutput = `select-wrapper ${icon ? 'has-suffix-icon' : ''} ${className}`;

    return (
        <div ref={element} className={classOutput}>
            <div className={selectClassOutput}>
                {/* Icon must be before Select otherwise can't be clicked */}
                {icon && <Icon icon={icon} />}
                <button
                    type="button"
                    onClick={onClick}
                    onKeyPress={onClick}
                    className="select"
                    disabled={disabled}
                    id={id}
                    aria-controls={id}
                    role="combobox"
                    aria-expanded={isOpen}
                >
                    {!text && (
                        <span className="select-placeholder">{placeholder}</span>
                    )}
                    {text && (
                        <span className="select-text">{text}</span>
                    )}
                </button>
            </div>
            {children && (
                <div className="select-custom-content">
                    {children}
                </div>
            )}
        </div>
    );
};

export default SelectCustom;
