import React, { useEffect, useState } from 'react';
import Pagination from '~2-components/FilteredListing/Pagination/Pagination';
import { ItemPaginationProps } from '~2-components/FilteredListing/FilteredListingType';

const ItemPagination = (props: ItemPaginationProps) => {
    const getPageRange = () => (window && window.innerWidth >= 768 ? 5 : 1);
    const [pageRangeDisplayed, setPageRangeDisplayed] = useState(
        getPageRange(),
    );
    const { totalPages, currentPage, changePage } = props;

    const onResize = () => {
        setPageRangeDisplayed(getPageRange());
    };

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return function cleanUp() {
            window.removeEventListener('resize', onResize);
        };
    });

    if (totalPages <= 1) {
        return null;
    }

    const clickPage = (value: { selected: number }) => {
        changePage(value.selected);
    };

    return (
        <div className="fl-pagination">
            <Pagination
                pageNum={totalPages}
                pageRangeDisplayed={pageRangeDisplayed}
                marginPagesDisplayed={1}
                clickCallback={clickPage}
                forceSelected={currentPage}
                initialSelected={currentPage}
                pageClassName="page"
            />
        </div>
    );
};

export default ItemPagination;
