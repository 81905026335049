import React, { useEffect, useState, useRef } from "react";
import { MultiCheckboxFilterProps } from "../../FilteredListingType";
import SelectCustom from "~1-core/form-elements/SelectCustom";
import Checkbox from "~1-core/form-elements/Checkbox";

const MultiCheckboxFilter = (props: MultiCheckboxFilterProps) => {
  const {
    filter,
    classModifier,
    selectedItems,
    toggleFilters,
    uniqueId,
    placeholder
  } = props;
  const rootClass = "multi-checkbox-filter";
  const openClass = "is-open";
  const [isOpen, setIsOpen] = useState(false);
  const element = useRef<HTMLDivElement>(null);

  const handleClick = (e: Event) => {
    if (
      element &&
      element.current &&
      element.current.contains(e.target as Node | null)
    ) {
      // handle click inside
      return;
    }
    // handle click outside
    setIsOpen(false);
  };

  // add & remove listener on didMount and unMount
  useEffect(() => {
    window.addEventListener("mousedown", handleClick);

    // returned function will be called on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const listId = `multi-checkbox-${filter.code}-${uniqueId}`;

  return (
    <div className="multi-checkbox-filter filter-item">
      <SelectCustom icon="caret-down" id={listId} placeholder={placeholder}>
        {filter.values.map((filterValue, i) => {
          const isActive = selectedItems.find(
            (selected) => selected.param === filterValue.param
          );
          return (
            <Checkbox
              key={`${filterValue.value}-${i}`}
              id={`${filter.code}-${filterValue.value}-${uniqueId}`}
              onChange={() => toggleFilters(filter, filterValue)}
              onKeyPress={() => toggleFilters(filter, filterValue)}
              checked={!!isActive}
              label={filterValue.value}
              value={filterValue.value}
            />
          );
        })}
      </SelectCustom>
    </div>
  );
};

export default MultiCheckboxFilter;
