import React from 'react';
import { NoItemsProps } from '~2-components/FilteredListing/FilteredListingType';

const NoItems = (props: NoItemsProps) => {
    const { resetFilters } = props;

    return (
        <div className="results-no-items">
            <h2>No results found</h2>
            <p>
                There are no items that match your query.
                <button
                    className="results-no-items-btn"
                    type="button"
                    onClick={resetFilters}
                >
                    Reset filters
                </button>
            </p>
        </div>
    );
};

export default NoItems;
