/**
 * Return an array of objects which have properties and values that matches the passed properties object
 *
 * @param {Array} set - the array of objects
 * @param {Object} properties - the object to match with
 * @returns {Array} Returns - An array of objects that matched the passed in properties object
 */
export default function findByMatchingProperties(set, properties) {
    const prefilter = set.filter(item => item !== null);
    return prefilter.filter((entry) => {
        return Object.keys(properties).every((key) => entry[key] === properties[key]);
    });
}
