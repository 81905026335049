export const PAGINATION_DISABLED_CLASSNAME = 'pagination-disabled';
export const PAGINATION_SELECTED_CLASSNAME = 'pagination-selected';
export const PAGINATION_MOBILE_VIEW_CLASSNAME = 'pagination-mobile-view';


export default {
    PAGINATION_DISABLED_CLASSNAME,
    PAGINATION_SELECTED_CLASSNAME,
    PAGINATION_MOBILE_VIEW_CLASSNAME,
};
