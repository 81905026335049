import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Icon from '~2-components/IconInline/IconInline';
import PageView from './PageView';
import BreakView from './BreakView';
import { PaginationProps } from './PaginationType';
import { PAGINATION_DISABLED_CLASSNAME, PAGINATION_MOBILE_VIEW_CLASSNAME } from './Constants';
import { ClickOrPressEvent } from '~/@types/general';

const Pagination = (props: PaginationProps) => {
    const {
        pageNum,
        pageRangeDisplayed,
        marginPagesDisplayed,
        selectedPage = 0,
        initialSelectedPage = null,
        className = '',
        prevIcon = 'chevron-left',
        previousLabel = null,
        nextIcon = 'chevron-right',
        nextLabel = null,
        breakLabel = <Icon icon="more-horizontal" />,
        clickCallback = () => null,
        queryParamPage = 'page',
    } = props;

    const [selected, setSelected] = useState(initialSelectedPage || selectedPage);

    const onPageChange = (selectedItem: number) => {
        if (
            typeof clickCallback !== 'undefined'
            && typeof clickCallback === 'function'
        ) {
            clickCallback({ selected: selectedItem });
        }
    };

    useEffect(() => {
        if (initialSelectedPage) {
            onPageChange(initialSelectedPage);
        }
    });

    useEffect(() => {
        setSelected(selectedPage);
    }, [selectedPage]);

    const handlePageSelected = (newPage: number) => {
        if (selected === newPage) return;

        setSelected(newPage);

        onPageChange(newPage);
    };

    const handleClickOrPress = (e: ClickOrPressEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.nativeEvent) {
            e.nativeEvent.stopImmediatePropagation();
        }
    };

    const handlePreviousPage = (e: ClickOrPressEvent) => {
        handleClickOrPress(e);
        if (selected > 0) {
            handlePageSelected(selected - 1);
        }
    };

    const handleNextPage = (e: ClickOrPressEvent) => {
        handleClickOrPress(e);
        if (selected < pageNum - 1) {
            handlePageSelected(selected + 1);
        }
    };

    const pagination = () => {
        const itemsArray: JSX.Element[] = [];
        let isPrevPageView = true;

        if (pageNum <= pageRangeDisplayed) {
            // If pageNum less than pageRangeDisplayed, just show all numbers without breakview
            for (let index = 0; index < pageNum; index += 1) {
                const pageClassName = (index >= selected - 1 && index <= selected + 1)
                    ? PAGINATION_MOBILE_VIEW_CLASSNAME
                    : '';

                itemsArray.push(
                    <PageView
                        key={`key${index}`}
                        onClick={(e: ClickOrPressEvent) => {
                            handleClickOrPress(e);
                            handlePageSelected(index);
                        }}
                        selected={selected === index}
                        className={pageClassName}
                        queryParamPage={queryParamPage}
                        page={index + 1}
                    />,
                );
            }
        } else {
            // Else check for breakview
            let leftSide = Math.round(pageRangeDisplayed / 2);
            let rightSide = pageRangeDisplayed - leftSide;

            if (selected > pageNum - (pageRangeDisplayed / 2)) {
                rightSide = pageNum - selected;
                leftSide = pageRangeDisplayed - rightSide;
            } else if (selected < (pageRangeDisplayed / 2)) {
                leftSide = selected;
                rightSide = pageRangeDisplayed - leftSide;
            }

            for (let index = 0; index < pageNum; index += 1) {
                const pageClassName = (index >= selected - 1 && index <= selected + 1)
                    ? PAGINATION_MOBILE_VIEW_CLASSNAME
                    : '';

                const page = index + 1;

                if (
                    page <= marginPagesDisplayed
                    || page > pageNum - marginPagesDisplayed
                    || (index >= selected - leftSide
                        && index <= selected + rightSide)
                ) {
                    const pageView = (
                        <PageView
                            key={`key${index}`}
                            onClick={(e: ClickOrPressEvent) => {
                                handleClickOrPress(e);
                                handlePageSelected(index);
                            }}
                            selected={selected === index}
                            className={pageClassName}
                            page={index + 1}
                            queryParamPage={queryParamPage}
                        />
                    );
                    itemsArray.push(pageView);
                    isPrevPageView = true;
                } else {
                    const breakView = (
                        <BreakView
                            key={`key${index}`}
                            breakLabel={breakLabel}
                        />
                    );
                    if (isPrevPageView) {
                        itemsArray.push(breakView);
                    }
                    isPrevPageView = false;
                }
            }
        }

        return itemsArray;
    };

    const previousClasses = classNames({
        [PAGINATION_DISABLED_CLASSNAME]: selected === 0,
    });

    const nextClasses = classNames({
        [PAGINATION_DISABLED_CLASSNAME]: selected === pageNum - 1,
    });

    let prevUrl = (typeof window !== 'undefined') ? `${window.location.href.split('?')[0]}?${queryParamPage}=${selected}` : '';

    let nextUrl = (typeof window !== 'undefined') ? `${window.location.href.split('?')[0]}?${queryParamPage}=${selected + 2}` : '';

    if (selected === 0) {
        prevUrl = '/';
    } else if (selected === pageRangeDisplayed) {
        nextUrl = '/';
    }

    return (
        <nav className={`pagination ${className}`}>
            <ul className="pagination-item-wrapper">
                <li className={`pagination-item pagination-item-prev ${previousClasses}`}>
                    <a
                        href={prevUrl}
                        onClick={handlePreviousPage}
                        onKeyPress={handlePreviousPage}
                        className="pagination-item-content"
                        rel="prev"
                    >
                        <Icon icon={prevIcon} />
                        {previousLabel && (
                            <span className="pagination-item-prev-label">{previousLabel}</span>
                        )}
                    </a>
                </li>

                {pagination()}

                <li className={`pagination-item pagination-item-next ${nextClasses}`}>
                    <a
                        href={nextUrl}
                        onClick={handleNextPage}
                        onKeyPress={handleNextPage}
                        className="pagination-item-content"
                        rel="next"
                    >
                        {nextLabel && (
                            <span className="pagination-item-next-label">{nextLabel}</span>
                        )}
                        <Icon icon={nextIcon} />
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
