import React, { useState, useEffect, useRef } from "react";
import { Link } from 'gatsby';
import { createBrowserHistory } from "history";
import {
    Props,
    Item,
    Settings,
    FilterOptions,
    FilteredListingDataProps,
    ToggleFilterFunction,
    ChangePageFunction,
    UpdateSortFunction,
    ResetFiltersFunction,
} from "~2-components/FilteredListing/FilteredListingType";
import fetch from "~2-components/FilteredListing/utils/fetch";
import {
    initActiveFilters,
    getActiveFiltersWithValues,
    updateActiveFiltersOnIndex,
    isAllActiveFiltersEmpty,
    isRequiredActiveFiltersEmpty,
} from "./utils/active-filters";
import {
    getQueryString,
    parseQueryString,
    buildQueryString,
} from "./utils/query-string";
import { sortItems, filterItems, paginateItems } from "./utils/item-filter";
import Loader from "../Loader/Loader";
// Main Components Sections
import Filters from "./Filters/Filters";
import ActiveFilters from "./ActiveFilters/ActiveFilters";
import Toolbar from "./Toolbar/Toolbar";
import Results from "./Results/Results";
import { QUERY_PARAM_SORT, QUERY_PARAM_PAGE } from "./Constants/filter-keys";

const defaultValue = {
    data: {
        contentData: [],
        filterOptions: [],
        settings: {
            activeFiltersLabel: "Current Filters:",
            keywordPlaceholderText: "Quick find",
            keywordSearchableFields: ["title", "introduction"],
            itemsPerPage: 99,
            showActiveFilters: true,
            showResultCount: true,
            showResetFilters: true,
            showPagination: true,
            sortBy: [],
            queryParam: "listing",
            initialSort: 0,
        },
    },
    tileType: "tile-article",
    tileContainerColumns: "three_columns",
    currentSort: "default",
    currentPage: 0,
    url: null,
    contentLoadingTimeout: 100,
};

const history = typeof window !== "undefined" ? createBrowserHistory() : null;

const FilteredListing = (props: Props) => {
    const {
        data = null,
        tileType = defaultValue.tileType,
        tileContainerColumns = defaultValue.tileContainerColumns,
        url = defaultValue.url,
    } = props;

    const [isLoading, setIsLoading] = useState(!data);
    const [errorMessage, setErrorMessage] = useState("");
    const [contentIsLoading, setContentIsLoading] = useState(true);

    const initialActiveFilter = data
        ? initActiveFilters(data.filterOptions)
        : defaultValue.data.filterOptions;
    const [activeFilters, setActiveFilters] = useState(initialActiveFilter);
    const [dataState, setDataState] = useState(data || defaultValue.data);
    const [currentSort, setCurrentSort] = useState(data && data.settings.initialSort > 0 ? data.settings.initialSort - 1 : defaultValue.currentSort);
    const [currentPage, setCurrentPage] = useState(defaultValue.currentPage);
    const [minDate, setMinDate] = useState(
        data && data.contentData.length > 0 ? data.contentData[0].timestamp : 0
    );
    const [maxDate, setMaxDate] = useState(
        data && data.contentData.length > 0 ? data.contentData[data.contentData.length - 1].timestamp : 0
    );

    const appElement = useRef<HTMLDivElement>(null);

    const getUrlFilter = () => {
        const browserUrl = getQueryString();
        const {
            filterOptions,
            settings: { queryParam },
        } = dataState;

        // const activeFilters = this.state.activeFilters.length > 0 || initActiveFilters(this.state.data.filterOptions);
        const presetFilters = parseQueryString(
            browserUrl,
            activeFilters,
            currentPage,
            filterOptions,
            queryParam
        );
        if (presetFilters) {
            if (
                JSON.stringify(presetFilters.activeFilters) !==
                JSON.stringify(activeFilters)
            ) {
                setActiveFilters(presetFilters.activeFilters);
            }

            if (presetFilters[QUERY_PARAM_SORT] !== currentSort) {
                setCurrentSort(presetFilters[QUERY_PARAM_SORT]);
            }

            if (presetFilters[QUERY_PARAM_PAGE] !== currentPage) {
                setCurrentPage(presetFilters[QUERY_PARAM_PAGE]);
            }
        }
    };

    const updateSort: UpdateSortFunction = (sortName) => {
        setContentIsLoading(true);
        setCurrentSort(sortName);
        setCurrentPage(0);
    };

    const updateRequiredActiveFilters = () => {
        const { filterOptions } = dataState;

        const updatedRequiredFilters = activeFilters.map((item, i) => {
            // Clone of active filters
            const newItem = JSON.parse(JSON.stringify(item));
            if (item.values && item.values.length === 0 && item.required) {
                // fill value with the first variable
                newItem.values.push(filterOptions[i].values[0]);
                return newItem;
            }

            return newItem;
        }) as FilterOptions[];

        setActiveFilters(updatedRequiredFilters);
    };

    // componentDidMount or componentDidUpdate based on data props
    useEffect(() => {
        if (data) {
            getUrlFilter();
        } else {
            fetch(`${url}`).then((newData: FilteredListingDataProps) => {
                if (newData) {
                    setDataState(newData);
                    setMinDate(newData.contentData[0].timestamp);
                    setMaxDate(
                        newData.contentData[newData.contentData.length - 1].timestamp
                    );
                    setActiveFilters(newData.filterOptions);
                    getUrlFilter();
                } else {
                    // eslint-disable-next-line quotes
                    const dataMessage = "Sorry data can't be found";
                    // const dataMessage = newData ? newData.message || null : null;
                    setErrorMessage(dataMessage);
                    setIsLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    // componentDidUpdate for url query parameter
    useEffect(() => {
        const {
            settings: { queryParam },
        } = dataState;

        const queryParams = buildQueryString({
            activeFilters,
            currentSort,
            currentPage,
            queryParam,
        });
        if (history) {
            history.replace({
                pathname: window.location.pathname,
                search: queryParams,
            });
        }

        if (isRequiredActiveFiltersEmpty(activeFilters)) {
            updateRequiredActiveFilters();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilters, currentSort, currentPage]);

    // Set contentIsLoading to false on timeout once set to true
    useEffect(() => {
        if (contentIsLoading) {
            setTimeout(() => {
                setContentIsLoading(false);
            }, defaultValue.contentLoadingTimeout);
        }
    }, [contentIsLoading]);

    const backToTop = () => {
        if (!appElement || !appElement.current) return;

        window.scrollTo({
            top: appElement.current.offsetTop,
            behavior: "smooth",
        });
    };

    const useFilteredItems = (contentData: Item[], settings: Settings) => {
        const activeFiltersWithValues = getActiveFiltersWithValues(activeFilters);
        const { initialSort, sortBy, keywordSearchableFields, itemsPerPage } = settings;
        const filteredItems = filterItems(
            activeFiltersWithValues,
            contentData,
            keywordSearchableFields
        );

        let sortedItems: Item[];
        if (sortBy && Array.isArray(sortBy)) {
            // find active sort param inside setting sort to get the object
            let currentSortObject = null;

            if (!currentSort && initialSort > 0) {
                currentSortObject = sortBy[initialSort - 1];
            }
            if (currentSort) {
                currentSortObject = sortBy.find(
                    (item) => item.param === currentSort
                );
            }
            // if not found from sort setting something is wrong, make it same
            if (!currentSortObject) {
                sortedItems = filteredItems;
            } else {
                sortedItems = sortItems(filteredItems, currentSortObject);
            }
        } else {
            sortedItems = filteredItems;
        }

        const pagedItems = paginateItems(
            sortedItems,
            currentPage,
            itemsPerPage
        );

        return { filteredItems, pagedItems };
    };

    const changePage: ChangePageFunction = (pageNum) => {
        setCurrentPage(pageNum);
        backToTop();
    };

    const resetFilters: ResetFiltersFunction = (event) => {
        if (contentIsLoading || isAllActiveFiltersEmpty(activeFilters)) return;
        event.preventDefault();
        setContentIsLoading(true);
        setActiveFilters(initActiveFilters(dataState.filterOptions));
        setCurrentPage(0);
    };

    const toggleFilters: ToggleFilterFunction = (
        filter,
        filterValue,
        alwaysReplace = false
    ) => {
        if (contentIsLoading) return;

        // Get index of the current active filter
        const activeFilterIndex = activeFilters.findIndex(
            (item) => item.code === filter.code
        );

        if (activeFilterIndex < 0) return;
        const newActiveFilters = updateActiveFiltersOnIndex(
            activeFilters,
            activeFilterIndex,
            filterValue,
            alwaysReplace
        );

        setContentIsLoading(true);
        setActiveFilters(newActiveFilters);
        setCurrentPage(0);
    };

    const {
        filterOptions = defaultValue.data.filterOptions,
        contentData = defaultValue.data.contentData,
        settings = defaultValue.data.settings,
    } = dataState;

    const { filteredItems, pagedItems } = useFilteredItems(contentData, settings);

    return (
        <div className="filtered-listing page-section">
            <div className="fl-app" ref={appElement}>
                {errorMessage && (
                    <p className="validation-message">
                        Oops, something went wrong: {/* this.state.APIMessage */}
                    </p>
                )}
                {isLoading && !errorMessage && (
                    <div className="filtered-listing-loader">
                        <Loader />
                    </div>
                )}
                {!isLoading && !errorMessage && (
                    <>
                        {filterOptions.length > 0 && (
                            <>
                                <Filters
                                    filters={filterOptions}
                                    filterSettings={settings}
                                    activeFilters={activeFilters}
                                    toggleFilters={toggleFilters}
                                    resetFilters={resetFilters}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    uniqueId={settings.queryParam}
                                />
                                <ActiveFilters
                                    activeFiltersLabel={settings.activeFiltersLabel}
                                    activeFilters={activeFilters}
                                    showActiveFilters={settings.showActiveFilters}
                                    resetFilters={resetFilters}
                                    toggleFilters={toggleFilters}
                                />
                            </>
                        )}
                        {contentIsLoading && (
                            <div className="fl-loader">
                                <Loader />
                            </div>
                        )}
                        {!contentIsLoading && (
                            <Results
                                items={pagedItems}
                                tileContainerColumns={tileContainerColumns || ""}
                                tileType={tileType || ""}
                                totalPages={Math.ceil(
                                    filteredItems.length / settings.itemsPerPage
                                )}
                                currentPage={currentPage}
                                changePage={changePage}
                                resetFilters={resetFilters}
                                showPagination={settings.showPagination || true}
                            />
                        )}
                        {!contentIsLoading && tileType === "articles" && (
                            <div className="fl-footer">
                                <Link to="/all-news-articles">
                                    All News Articles
                                </Link>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default FilteredListing;
